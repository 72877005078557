import { RouteSectionProps } from "@solidjs/router";

import { PageLayout } from "#root/layouts/PageLayout";
import { SEO } from "#root/components/SEO";
import { useTranslation } from "#root/domain/i18n";

export default function TermsLayout(props: RouteSectionProps) {
  const { t } = useTranslation();

  return (
    <>
      <SEO title={t("terms.title")} />
      <PageLayout>{props.children}</PageLayout>
    </>
  );
}
